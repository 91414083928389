(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageBreakout', [
			'seedcodeCalendar',
			'utilities',
			manageBreakout,
		]);

	function manageBreakout(seedcodeCalendar, utilities) {
		return {
			availableFields: availableFields,
		};

		function availableFields() {
			var fields = []; //[{label: '', name: ''}];
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');

			if (config.isShare) {
				return [config.shareBreakout];
			} else if (!schedules) {
				return [];
			}

			for (var i = 0; i < schedules.length; i++) {
				if (!schedules[i].status.selected) {
					continue;
				}

				// Add builtin fields (title field)
				addField('titleEdit', schedules[i]);
				// Add builtin fields (description field)
				addField('description', schedules[i]);
				// Add builtin fields (location field)
				addField('location', schedules[i]);
				// Add builtin fields (project field)
				addField('projectName', schedules[i]);
				// Add builtin fields (contact field)
				addField('contactName', schedules[i]);

				// Add custom fields
				addCustomFieldNames(schedules[i].customFields);

				// Add related fields
				addRelatedObjects(schedules[i], 'contact');
				addRelatedObjects(schedules[i], 'project');
			}

			// De-dupe the result and return
			return utilities.removeObjectArrayDupes(
				fields,
				'label',
				null,
				true
			);

			// Helper functions
			function addField(fieldName, schedule) {
				var defaultLabelMap = seedcodeCalendar.get('defaultLabelMap');
				var labelMap = schedule.labelMap;
				var fieldMap = schedule.fieldMap;
				var fieldObject;
				var label;

				// If field is unused don't add it
				if (
					(schedule.unusedMap && schedule.unusedMap[fieldName]) ||
					(fieldMap && !fieldMap[fieldName])
				) {
					return;
				}
				//Add builtin fields (title field)
				// Label match
				if (labelMap && labelMap[fieldName]) {
					label = labelMap[fieldName];
				}
				// Default label match
				else if (defaultLabelMap && defaultLabelMap[fieldName]) {
					label = defaultLabelMap[fieldName];
				}
				// Label not available - If this scenario happens we most likely need to add a value to default label
				else {
					return;
				}

				fieldObject = {
					label: label,
					name: fieldName,
				};
				fields.push(fieldObject);
			}

			function addCustomFieldNames(customFieldData) {
				var fieldObject;
				if (customFieldData) {
					for (var property in customFieldData) {
						fieldObject = {
							label: customFieldData[property].name,
							name: property, // Name shouldn't be used for anything currently as we are matching from label
						};
						fields.push(fieldObject);
					}
				}
			}

			function addRelatedObjects(schedule, type) {
				var fieldObject;
				var relatedObject = schedule[type + 'Objects'];
				if (!relatedObject) {
					return;
				}
				for (var property in relatedObject) {
					if (relatedObject[property].objectDisplay) {
						fieldObject = {
							label: relatedObject[property].objectDisplay,
							name: type + 'Name',
						};

						fields.push(fieldObject);
					}
				}
			}
		}
	}
})();
